import {
  Component,
  EventEmitter,
  Input,
  OnInit,
  Output,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { ApicommunicationService } from '../services/apicommunication.service';
import { ITipData } from '../models/tip-view.model';
import { states } from 'src/assets/states';
import { ToastrService } from 'ngx-toastr';
import { questions } from 'src/assets/questions-test';
@Component({
  selector: 'app-questions',
  templateUrl: './questions.component.html',
  styleUrls: ['./questions.component.css'],
})
export class QuestionsComponent implements OnInit {
  @Output('tip-model') tipModelEmitter = new EventEmitter();
  @Output('submit-trigger') submittrigger = new EventEmitter();
  steps = 1;
  // store the api
  alabamaSchool: any = [];
  northCarolinaSchool: any = [];
  mississpiSchool: any = [];
  schoolResult: any = [];
  // use to store the school id
  schoolOptions: any;
  schoolID: string = '';
  liscenceStates = states;
  //store the update id after create api is call
  updateId: number;
  // this is use to show the loader if the api have no respose yet
  loader: boolean = false;
  model = [
    {
      question: 'What is the name of the school you are concerned about?',
      answer: null,
      completed: false,
      id: 1,
      functionInput: 'schoolName',
      schoolName: '',
      //validationRegex: /^\s*$/,
      validationErrorMessage: 'Please select a school',
    },
    {
      question: 'What is the name of the person you are concerned about?',
      answer: '',
      completed: false,
      id: 2,
      functionInput: 'personName',
      validationRegex: /^(?=.{1,150}$)[a-zA-Z ,.'-]+$/,
      validationErrorMessage: 'Please enter a valid name.',
    },
    {
      question: 'What is the age of the person you are concerned about?',
      answer: '',
      completed: false,
      id: 3,
      functionInput: 'age',
      validationRegex: /^([1-9]|[1-9][0-9]|100)$/,
      validationErrorMessage: 'Please enter a valid age.',
    },
    {
      question: "What is this person's gender?",
      answer: '',
      completed: false,
      id: 4,
      functionInput: 'gender',
      validationRegex: /^.+$/,
      validationErrorMessage: 'Please select a gender.',
    },
    {
      question: 'Are they going to be a victim or offender?',
      answer: '',
      completed: false,
      id: 5,
      functionInput: 'personType',
      validationRegex: /^.+$/,
      validationErrorMessage: 'Please select a response.',
    },
    {
      question: "What is the person's ethnicity?",
      answer: '',
      completed: false,
      id: 6,
      functionInput: 'ethnicity',
      validationRegex: /^.+$/,
      validationErrorMessage: 'Please select a response.',
    },
    {
      question:
        'Can you describe what they look like? (height, weight, hair color, eye color, etc?)',
      answer: '',
      completed: false,
      id: 7,
      functionInput: 'description',
      validationRegex: /^(?=.{1,150}$)[a-zA-Z ,.'-]+$/,
      validationErrorMessage: 'Please enter a response.',
    },
    {
      question: 'What grade are they in?',
      answer: '',
      completed: false,
      id: 8,
      functionInput: 'grade',
      validationRegex: /^([1-9]|[1-9][0-9]|100)(st|nd|rd|th)?$/i,
      validationErrorMessage: 'Please enter a valid grade.',
    },
    {
      question: 'What kind of event will this be?',
      answer: '',
      completed: false,
      id: 9,
      functionInput: 'eventType',
      validationRegex: /^.+$/,
      validationErrorMessage: 'Please selct a response.',
    },
    {
      question: 'Tell us what is going to happen?',
      answer: '',
      completed: false,
      id: 10,
      functionInput: 'whatHappens',
      validationRegex: /^(?=.{1,150}$)[a-zA-Z ,.'-]+$/,
      validationErrorMessage: 'Please enter a valid response.',
    },
    {
      question: 'When is this going to happen?',
      answer: '',
      completed: false,
      id: 11,
      functionInput: 'date',
      validationRegex: /^.{10}$/,
      validationErrorMessage: 'Please enter a valid date.',
    },
    {
      question:
        'What is the make of the vehicle that is involved if applicable? (Ford, Chevy, Toyota, etc.)',
      answer: '',
      completed: false,
      id: 12,
      functionInput: 'vehicleMake',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z ,.'-\/]*$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question:
        'What is the model of the vehicle if applicable? (Camaro, Mustang, etc.)',
      answer: '',
      completed: false,
      id: 13,
      functionInput: 'vehicleModel',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z ,.'-\/]*$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question: 'What is the color of the vehicle involved if applicable?',
      answer: '',
      completed: false,
      id: 14,
      functionInput: 'vehicleColor',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z ,.'-\/]*$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question: 'What is the year model of the vehicle involved if applicable?',
      answer: '',
      completed: false,
      id: 15,
      functionInput: 'vehicleYear',
      validationRegex: /^\d{4}$|^$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question:
        'What state is on the license plate of the vehicle involved if applicable?',
      answer: '',
      completed: false,
      id: 16,
      functionInput: 'vehicleState',
      validationRegex: /^$|^.+$/,
      validationErrorMessage: 'Please select a response or leave blank.',
    },
    {
      question: 'What is the license plate number if applicable?',
      answer: '',
      completed: false,
      id: 17,
      functionInput: 'vehicleLicense',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z0-9 ,.'-\/]*$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question:
        'Are there any other noticeable features you would like to tell us about the vehicle if applicable?',
      answer: '',
      completed: false,
      id: 18,
      functionInput: 'vehicleDescription',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z0-9 ,.'-\/]*$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
    {
      question: '(Optional) What is your name?',
      answer: '',
      completed: false,
      id: 19,
      functionInput: 'submitterName',
      validationRegex: /^(?=.{0,150}$)[a-zA-Z ,.'-]*$/,
      validationErrorMessage: 'Please enter a valid name or leave blank.',
    },
    {
      question: '(Optional) What is your phone number?',
      answer: '',
      completed: false,
      id: 20,
      functionInput: 'submitterPhoneNumber',
      validationRegex:
        /^$|^(\+\d{1,2}\s)?(\(?\d{3}\)?[\s.-]?)?[\d]{3}[\s.-]?[\d]{4}$/,
      validationErrorMessage:
        'Please enter a valid phone number or leave blank.',
    },
    {
      question: '(Optional) Can we contact you for more information?',
      answer: '',
      completed: false,
      id: 21,
      functionInput: 'contactSubmitter',
      validationRegex: /^$|^.+$/,
      validationErrorMessage: 'Please enter a valid response or leave blank.',
    },
  ];

  // use to count the progress in the progress bar
  completePercent = 0;

  constructor(
    private https: HttpClient,
    private service: ApicommunicationService,
    private toastr: ToastrService
  ) {}

  ngOnInit(): void {
    // nested api call in order to push all the school together
    // api should call one at a time
    this.service.getBuildingOptions('alabama').subscribe((alabamaRes) => {
      this.alabamaSchool = alabamaRes;
      this.service
        .getBuildingOptions('north-carolina')
        .subscribe((northCarolinaRes) => {
          this.northCarolinaSchool = northCarolinaRes;
          this.schoolResult = [
            ...this.northCarolinaSchool.data,
            ...this.alabamaSchool.data,
          ];
          this.service
            .getBuildingOptions('mississippi')
            .subscribe((mississipiRes) => {
              this.mississpiSchool = mississipiRes;
              this.schoolResult = [
                ...this.northCarolinaSchool.data,
                ...this.alabamaSchool.data,
                ...this.mississpiSchool.data,
              ];
            });
        });
    });
  }

  nextButton() {
    // run validation, if validateText not "", then prevent user to go next, otherwise, move to next question
    // this is for storing the model to pass to the parent to component
    this.tipModelEmitter.emit(this.model);

    // statement to call the api for each click
    if (this.steps === 1) {
      if (!this.schoolID) {
        this.toastr.error(this.model[0].validationErrorMessage);
      } else {
        // this is for progress bar whenever the user click the next button the progress bar go
        // this bind with the html using two way binding
        this.completePercent = (this.steps * 110) / this.model.length;

        this.loader = true;
        this.service.createTip(this.schoolID).subscribe((createData) => {
          if (createData) {
            this.loader = false;
          }
          this.updateId = createData.data.id;
        });
        this.model[0].answer = this.schoolID;

        // this enable the next button go to the next question after the api is call
        this.steps = this.steps + 1;
        // call the update api after questions 1
      }
    } else if (this.steps > 1) {
      const currentStep = this.steps - 1;

      const answer = this.model[currentStep].answer;
      const validationRegex = new RegExp(
        this.model[currentStep].validationRegex
      );

      if (!validationRegex.test(answer)) {
        this.toastr.error(this.model[currentStep].validationErrorMessage);
        return;
      }

      // this is for progress bar whenever the user click the next button the progress bar go
      // this bind with the html using two way binding
      this.completePercent = (this.steps * 110) / this.model.length;
      this.service
        .updateTip(this.updateId, this.model)
        .subscribe((updateData) => {});
      // this enable the next button go to the next question after the api is call
      this.steps = this.steps + 1;
    }
  }
  // go back to the question
  previous() {
    this.steps = this.steps - 1;
    this.completePercent =
      (this.steps * 110) / this.model.length - 5.23809523809523796;
  }

  // this button will lead to the review page
  review() {
    // use to transfer the updateid from the child comp to parent comp
    this.submittrigger.emit(this.updateId);
    this.service
      .updateTip(this.updateId, this.model)
      .subscribe((updateData) => {});
  }

  // function for when type something in the input field the data is retrieve from the datatable
  searchOnKeyUp(event) {
    const input = event.target.value;
    // spit out match object to the select event
    this.schoolOptions = this.schoolResult.find(({ label }) => label === input);
    this.schoolID = '130';
    // set the schoolID to default 130 if there no school id matches
    if (!this.schoolOptions?.id) {
      return this.schoolID;
    } else {
      this.schoolID = this.schoolOptions.id;
    }
  }
}
