export const states = 
[

{
    id: 1,
    state:'Alabama',
    postal: 'AL'
},
{
    id: 2,
    state:'Alaska',
    postal: 'AK'
},
{
    id: 4,
    state:'Arizona',
    postal: 'AZ'
},
{
    id: 5,
    state:'Arkansas',
    postal: 'AR'
},
{
    id: 6,
    state:'California',
    postal: 'CA'
},
{
    id: 8,
    state:'Colorado',
    postal: 'CO'
},
{
    id: 9,
    state:'Connecticut',
    postal: 'Ct'
},
{
    id: 10,
    state:'Delaware',
    postal: 'DE'
},
{
    id: 12,
    state:'Florida',
    postal: 'FL'
},
{
    id: 12,
    state:'Florida',
    postal: 'FL'
},
{
    id: 13,
    state:'Georgia',
    postal: 'GA'
},
{
    id: 15,
    state:'Hawaii',
    postal: 'HI'
},
{
    id: 16,
    state:'Idaho',
    postal: 'ID'
},
{
    id: 17,
    state:'Illinois',
    postal: 'IL'
},
{
    id: 18,
    state:'Indiana',
    postal: 'IN'
},
{
    id: 19,
    state:'Iowa',
    postal: 'IA'
},
{
    id: 20,
    state:'Kansas',
    postal: 'KS'
},
{
    id: 21,
    state:'Kentucky',
    postal: 'KY'
},
{
    id: 22,
    state:'Louisiana',
    postal: 'LA'
},
{
    id: 23,
    state:'Maine',
    postal: 'ME'
},
{
    id: 24,
    state:'Maryland',
    postal: 'MD'
},
{
    id: 25,
    state:'Massachusetts',
    postal: 'MA'
},
{
    id: 26,
    state:'Michigan',
    postal: 'MI'
},
{
    id: 27,
    state:'Minnesota',
    postal: 'MN'
},
{
    id: 28,
    state:'Mississippi',
    postal: 'MS'
},
{
    id: 29,
    state:'Missouri',
    postal: 'MO'
},
{
    id: 30,
    state:'Montana',
    postal: 'MT'
},
{
    id: 31,
    state:'Nebraska',
    postal: 'NE'
},
{
    id: 32,
    state:'Nevada',
    postal: 'NV'
},
{
    id: 33,
    state:'New Hampshire',
    postal: 'NH'
},
{
    id: 34,
    state:'New Jersey',
    postal: 'NJ'
},
{
    id: 35,
    state:'New Mexico',
    postal: 'NM'
},
{
    id: 36,
    state:'New York',
    postal: 'NY'
},
{
    id: 37,
    state:'North Carolina',
    postal: 'NC'
},
{
    id: 38,
    state:'North Dakota',
    postal: 'ND'
},
{
    id: 39,
    state:'Ohio',
    postal: 'OH'
},
{
    id: 40,
    state:'Oklahoma',
    postal: 'OK'
},
{
    id: 41,
    state:'Oregon',
    postal: 'OR'
},
{
    id: 42,
    state:'Pennsylvania',
    postal: 'PA'
},
{
    id: 44,
    state:'Rhode Island',
    postal: 'RI'
},
{
    id: 45,
    state:'South Carolina',
    postal: 'SC'
},
{
    id: 46,
    state:'South Dakota',
    postal: 'SD'
},
{
    id: 47,
    state:'Tennessee',
    postal: 'TN'
},
{
    id: 48,
    state:'Texas',
    postal: 'TX'
},
{
    id: 49,
    state:'Utah',
    postal: 'UT'
},
{
    id: 50,
    state:'Vermont',
    postal: 'VT'
},
{
    id: 51,
    state:'Virginia',
    postal: 'VA'
},
{
    id: 53,
    state:'Washington',
    postal: 'WA'
},
{
    id: 54,
    state:'West Virginia',
    postal: 'WV'
},
{
    id: 55,
    state:'Wisconsin',
    postal: 'WI'
},
{
    id: 56,
    state:'Wyoming',
    postal: 'WY'
},
]
