import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { OnlineStatusService, OnlineStatusType } from 'ngx-online-status';
@Component({
  selector: 'app-tip-console',
  templateUrl: './tip-console.component.html',
  styleUrls: ['./tip-console.component.css'],
})
export class TipConsoleComponent implements OnInit {
  public isDone = false;
  public currentTipModel: any = null;
  public submitId: number;

  isConnected = true;
  noInternetConnection: boolean;
  constructor( private onlineStatusService: OnlineStatusService, private route: Router ) {
    this.onlineStatusService.status.subscribe((status: OnlineStatusType) => {
      this.isConnected = (status === OnlineStatusType.ONLINE) ? true : false;
      if (this.isConnected) {
        this.noInternetConnection = false;
        this.route.navigate(['tips/questions/']);
      } else {
        this.noInternetConnection = true;
        this.route.navigate(['tips/questions/trying-to-connect']);
      }
    });
  }
  ngOnInit(): void {}

  public getCurrentTipFromQuestions(event) {
    this.currentTipModel = event;
  }

  public triggersubmit(event) {
    this.submitId = event;
    this.isDone = true;
  }
}
