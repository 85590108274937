import { Injectable } from '@angular/core';
import { Observable, throwError } from 'rxjs';
import { BuildingOption } from '../models/building-option.model';
import { ITipData, TipViewModel } from '../models/tip-view.model';
import { IAPIRsp } from '../models/api-rsp.model';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { catchError, tap } from 'rxjs/operators';
import { urls } from '../../assets/urls.obj';

@Injectable({
  providedIn: 'root',
})
export class ApicommunicationService {
  constructor(private httpClient: HttpClient) {}

  handleError(error: HttpErrorResponse) {
    return throwError(error);
  }

  createTip(buildingID: string): Observable<any> {
    const url = `${urls.createTip}${buildingID}`;
    return this.httpClient.post<IAPIRsp<TipViewModel>>(url, { json: {} })
    .pipe(
      tap((res) => {
        if (!res.data) {
          throw Error('API encountered error. Item may not exist in database.');
        }
      }),
      catchError(this.handleError)
    );
  }

  // Notice: This method expects an object of type "UpdateForm".
  // You can use Object.assign() to transfer properties (as long as the models are identical).
  // Also Note: The property 'json' needs to be of type "string" for this method to function properly.
  updateTip(id: number, tipData: ITipData[]): Observable<any> {
    const url = `${urls.updateTip}${id}`;
    for (const q of tipData) {
      if (q.answer !== '') {
        q.completed = true;
      } else {
        q.completed = false;
      }
    }
    return this.httpClient
      .post<IAPIRsp<TipViewModel>>(url, { json: { data: tipData } })
      .pipe(
        tap((res) => {
          if (!res.data) {
            throw Error(
              'API encountered error. Item may not exist in database.'
            );
          }
        }),
        catchError(this.handleError)
      );
  }

  submitTip(id: number, tipData: ITipData[]): Observable<any> {
    const url = `${urls.submitTip}${id}`;
    return this.httpClient
      .post<IAPIRsp<TipViewModel>>(url, { json: { data: tipData } })
      .pipe(
        tap((res) => {
          if (!res.data) {
            throw Error(
              'API encountered error. Item may not exist in database.'
            );
          }
        }),
        catchError(this.handleError)
      );
  }

  // This method will return the tip with status and type. Use this method and check the properties to verify progress.
  returnTip(tipID: number): Observable<IAPIRsp<TipViewModel>> {
    const url = `${urls.returnTip}${tipID.toString()}`;
    return this.httpClient.get<IAPIRsp<TipViewModel>>(url)
    .pipe(
      tap((res) => {
        if (!res.data || res.data == null) {
          throw Error('API encountered error. Item may not exist in database.');
        }
      }),
      catchError(this.handleError)
    );
  }

  // State names should be spelled out! example: "alabama"
  getBuildingOptions(stateName: string): Observable<IAPIRsp<BuildingOption>> {
    const url = `${urls.getBuildings}${stateName.toLowerCase()}-names`;
    return this.httpClient.get<IAPIRsp<BuildingOption>>(url)
    .pipe(
      tap((res) => {
        if (!res.data) {
          throw Error('API encountered error. Item may not exist in database.');
        }
      }),
      catchError(this.handleError)
    );
  }
}
