import { environment } from '../environments/environment';

const notifyBase = environment.notifyApiBase;
const version = environment.apiVersion;
const gatewayBase = environment.gatewayApibase;


export const urls =
  {
    createTip: notifyBase + '/' + version + '/Items/Create/FromTip/',
    updateTip: notifyBase + '/' + version + '/Items/Update/FromTipForm/',
    returnTip: notifyBase + '/' + version + '/Items/',
    submitTip: notifyBase + '/' + version + '/Items/Submit/FromTipForm/',
    getBuildings: gatewayBase + '/' + version + '/Forms/data/select/buildings-k12-',



  };
