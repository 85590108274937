import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import { HttpClientModule } from '@angular/common/http';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { IonicModule, IonicRouteStrategy } from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { QuestionsComponent } from './questions/questions.component';
import { HomeComponent } from './home/home.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { TipsReviewComponent } from './tips-review/tips-review.component';
import { NavigationBarComponent } from './navigation-bar/navigation-bar.component';
import { TipConsoleComponent } from './tip-console/tip-console.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { OnlineStatusModule } from 'ngx-online-status';
import { InternetConnectionComponent } from './internet-connection/internet-connection.component';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { ToastrModule } from 'ngx-toastr';

@NgModule({
  declarations: [
    HomeComponent,
    QuestionsComponent,
    AppComponent,
    TipsReviewComponent,
    ErrorViewComponent,
    NavigationBarComponent,
    TipConsoleComponent,
    ConfirmationPageComponent,
    InternetConnectionComponent,
  ],

  imports: [
    OnlineStatusModule,
    HttpClientModule,
    BrowserModule,
    IonicModule.forRoot(),
    AppRoutingModule,
    ReactiveFormsModule,
    FormsModule,
    BrowserAnimationsModule,
    ToastrModule.forRoot()
  ],
  providers: [{ provide: RouteReuseStrategy, useClass: IonicRouteStrategy }],
  bootstrap: [AppComponent],
})
export class AppModule {}
