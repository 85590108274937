import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { HomeComponent } from './home/home.component';
import { ErrorViewComponent } from './error-view/error-view.component';
import { TipConsoleComponent } from './tip-console/tip-console.component';
import { ConfirmationPageComponent } from './confirmation-page/confirmation-page.component';
import { InternetConnectionComponent } from './internet-connection/internet-connection.component';
const routes: Routes = [
  // Route for starting page
  {
    path: 'tips',
    pathMatch: 'full',
    component: HomeComponent,
  },
  // this will make sure the route for the startpage will come back
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'tips',
  },
  // this is the questions and review route
  {
    path: 'tips/questions',
    component: TipConsoleComponent,
  },
  // confirmation route
  {
    path: 'tips/questions/confirm',
    component: ConfirmationPageComponent,
  },
  {
    path: 'tips/questions/trying-to-connect',
    component: InternetConnectionComponent,
  },
  // ensure that their is an error if the route is not exist
  {
    path: '**',
    component: ErrorViewComponent,
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
