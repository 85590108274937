import { Component, Input, OnChanges, OnInit, SimpleChanges } from '@angular/core';
import { ApicommunicationService } from '../services/apicommunication.service';

@Component({
  selector: 'app-tips-review',
  templateUrl: './tips-review.component.html',
  styleUrls: ['./tips-review.component.css'],
})
export class TipsReviewComponent implements OnInit, OnChanges {
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('tip-model-from-questions') currentTipModel: any | null;
  // eslint-disable-next-line @angular-eslint/no-input-rename
  @Input('getId') submitId: number | null;

  constructor(private service: ApicommunicationService) {}

  ngOnInit(): void {}
  // when data change from question component it will also change from this component by using this
  // angular build in method
  ngOnChanges(changes: SimpleChanges): void {
    if (changes.currentTipModel) {
    } else if (changes.submitId) {
    }
  }
  // calling submit api when click submit tip also trigger confirmation page
  submitData() {
    this.service
      .submitTip(this.submitId, this.currentTipModel)
      .subscribe((updateData) => {});
  }
}
